<template>
    <div>
		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd()" v-permission="'SYS_MENU_INSERT'">{{translate('OPERATION.ADD')}}</el-button>
				<el-button type="warning" icon="el-icon-download" size="mini" @click="handleExport" v-permission="'SYS_MENU_EXPORT'">{{translate('OPERATION.EXPORT')}}</el-button>
			</el-col>
		</el-row>

		<CustomForm :dicts="dicts" :on-fresh="showCustomForm" @on-close="handlerCustomFormOnclose" :isAdd="true"></CustomForm>
	</div>
</template>

<script>
	import CustomForm from "./CustomForm";
	import {UserApi} from '@/api';
    export default {
        name: "ToolBar",
		components: {CustomForm},
		props: {
			dicts: {
				type: Object,
				default: {}
			}
		},
		data() {
			return {
				showCustomForm: false
			}
		},
		mounted() {
		},
		methods: {
			handlerCustomFormOnclose() {
				this.showCustomForm = false;
				this.commitChange();
			},
			handleAdd(){
				this.showCustomForm = true;
			},
			handleExport() {
				this.$confirm(this.translate(`Are you sure to export data of {'Menu'}?`, {module: 'Menu'}), this.translate('FROM.WARNING'), {
					confirmButtonText: this.translate('FROM.SUBMIT'),
					cancelButtonText: this.translate('FROM.CANCEL'),
					type: "warning"
				}).then(async () => {
					let result = (await UserApi.menu.export());
					if (result.success) {
						this.downloadFile(result.data);
					}
				});
			},
			commitChange() {
				this.$emit('on-change');
			},
		}
    }
</script>

<style scoped>

</style>
