<template>
	<div class="app-container">
		<ToolBar :dicts="dicts" @on-change="dealWithToolBarChange"></ToolBar>
		<CustomTable :dicts="dicts" :reQuery="reQuery"></CustomTable>
	</div>
</template>

<script>
	import ToolBar from "./ToolBar";
	import CustomTable from "./CustomTable";
	import { mapGetters } from 'vuex'
	import {GET_DICTS} from '@/store/constants';

	export default {
        name: "Index",
		components: {CustomTable, ToolBar},
		data(){
			return{
				reQuery: 0
			}
		},
		computed:{
        	...mapGetters({
				dicts: GET_DICTS
			})
		},
		methods: {
			dealWithToolBarChange() {
				this.reQuery = this.reQuery + 1;
			},
		}
	}
</script>

<style scoped>

</style>
